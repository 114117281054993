<template>
  <div class="auth-container">
    <div class="auth-content">
      <h2>{{ isLogin ? 'Login' : 'Register' }}</h2>
      <form @submit.prevent="handleSubmit">
        <div class="inputBox">
          <input v-model="username" type="text" required>
          <i>Username</i>
        </div>
        <div class="inputBox">
          <input v-model="password" type="password" required>
          <i>Password</i>
        </div>
        <button type="submit">{{ isLogin ? 'Login' : 'Register' }}</button>
        <p @click="toggleForm">{{ isLogin ? 'Don\'t have an account? Register here' : 'Already have an account? Login here' }}</p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      isLogin: true
    };
  },
  methods: {
    async handleSubmit() {
      const url = this.isLogin ? 'https://api.tractorbeam.app/auth/login' : 'https://api.tractorbeam.app/auth/register';
      try {
        const formData = new URLSearchParams();
        formData.append('username', this.username);
        formData.append('password', this.password);

        const response = await this.$http.post(url, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        if (this.isLogin) {
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('username', this.username);
          this.$store.dispatch('login', { apiKey: response.data.access_token, user: this.username });
          this.$router.push('/');
        } else {
          alert('Registration successful. Your API key is: ' + response.data.api_key);
        }
      } catch (error) {
        console.error(`${this.isLogin ? 'Login' : 'Registration'} failed:`, error);
      }
    },
    toggleForm() {
      this.isLogin = !this.isLogin;
    }
  }
};
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.auth-content {
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.auth-content h2 {
  margin-bottom: 20px;
  color: #333;
}

.inputBox {
  position: relative;
  margin-bottom: 20px;
}

.inputBox input {
  width: 100%;
  padding: 10px;
  background: #f1f1f1;
  border: none;
  outline: none;
  border-radius: 4px;
  transition: 0.3s;
}

.inputBox input:focus {
  background: #e0e0e0;
}

.inputBox i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
  transition: 0.3s;
}

.inputBox input:focus + i,
.inputBox input:not(:placeholder-shown) + i {
  top: 10px;
  font-size: 12px;
}

button {
  width: 100%;
  padding: 10px;
  background: #0f0;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}

button:hover {
  background: #0c0;
}

p {
  margin-top: 20px;
  color: #333;
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s;
}

p:hover {
  color: #555;
}
</style>
