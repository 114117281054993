<template>
  <div class="container">
    <h1>Welcome, {{ username }}</h1>
    <button @click="logout" class="btn btn-danger">Logout</button>
    <div class="api-key-box">
      <p><strong>API Key:</strong></p>
      <div class="api-key-container">
        <pre class="api-key">{{ apiKeyVisible ? apiKey : maskedApiKey }}</pre>
        <button @click="toggleApiKeyVisibility" class="btn btn-secondary reveal-button">{{ apiKeyVisible ? 'Hide' : 'Reveal' }}</button>
      </div>
    </div>
    <h2>Events</h2>
    <div class="filter-dropdown">
      <select v-model="filter" class="form-select" @change="filterEvents">
        <option value="">All</option>
        <option value="alert">Alerts</option>
        <option value="failure">Fail</option>
        <option value="warning">Warning</option>
        <option value="success">Success</option>
      </select>
      <button v-if="isPlaying" @click="stopAlertSound" class="btn btn-primary">Stop</button>
    </div>
    <table class="table table-hover table-bordered">
      <thead class="thead-dark">
        <tr>
          <th @click="sortTable('name')">Event Title</th>
          <th @click="sortTable('timestamp')">Date</th>
          <th @click="sortTable('source')">Source</th>
          <th @click="sortTable('priority')">Priority</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(event, index) in filteredEvents" :key="event.id">
          <tr :class="{ 'table-active': expandedIndex === index, 'high-priority': event.priority > 0 && !event.acknowledged }" @click="toggleDetails(index)">
            <td><strong>{{ event.name }}</strong></td>
            <td>{{ new Date(event.timestamp).toLocaleString() }}</td>
            <td>{{ event.source }}</td>
            <td>{{ event.priority }}</td>
          </tr>
          <tr v-if="expandedIndex === index">
            <td colspan="4">
              <div class="event-details">
                <p><strong>Description:</strong> {{ event.description }}</p>
                <p><strong>Output:</strong> {{ event.output }}</p>
                <p><strong>Result:</strong> {{ event.result }}</p>
                <button v-if="event.priority > 0 && !event.acknowledged" @click.stop="acknowledgeEvent(event.id)" class="btn btn-success">Ack</button>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      events: [],
      expandedIndex: null,
      filter: '',
      sortKey: 'timestamp',
      sortOrder: 'desc',
      username: localStorage.getItem('username'),
      apiKey: localStorage.getItem('token'),
      apiKeyVisible: false,
      isPlaying: false,
      unacknowledgedEvents: [],
      cooldownTimer: null,
      audio: null,
    };
  },
  computed: {
    maskedApiKey() {
      return '*'.repeat(this.apiKey.length);
    },
    filteredEvents() {
      let events = this.events;

      if (this.filter) {
        if (this.filter === 'alert') {
          events = events.filter(event => event.priority > 0 && !event.acknowledged);
        } else {
          events = events.filter(event => event.result === this.filter);
        }
      }

      return events.sort((a, b) => {
        if (this.sortKey === 'priority') {
          return this.sortOrder === 'asc' ? a.priority - b.priority : b.priority - a.priority;
        } else if (this.sortKey === 'timestamp') {
          return this.sortOrder === 'asc' ? new Date(a.timestamp) - new Date(b.timestamp) : new Date(b.timestamp) - new Date(a.timestamp);
        } else {
          if (a[this.sortKey] < b[this.sortKey]) return this.sortOrder === 'asc' ? -1 : 1;
          if (a[this.sortKey] > b[this.sortKey]) return this.sortOrder === 'asc' ? 1 : -1;
          return 0;
        }
      });
    }
  },
  mounted() {
    this.audio = new Audio('/alert-sound.mp3');
    this.fetchEvents();
    this.initSSE();
    console.log('Component mounted and methods called.');
    document.addEventListener('click', this.enableAudioPlayback);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.enableAudioPlayback);
    if (this.cooldownTimer) {
      clearTimeout(this.cooldownTimer);
    }
  },
  methods: {
    async fetchEvents() {
      try {
        const response = await this.$http.get('https://api.tractorbeam.app/api/events', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Fetched events:', response.data);
        this.events = response.data.map(event => {
          console.log('Event ID:', event.id); // Log the event ID
          return {
            ...event,
            priority: parseInt(event.priority, 10), // Ensure priority is an integer
            acknowledged: event.acknowledged || false // Ensure acknowledged field exists
          };
        });
        this.checkForUnacknowledgedEvents();
        console.log('Events data:', this.events);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    },
    initSSE() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found for SSE');
        return;
      }
      const eventSource = new EventSource(`https://api.tractorbeam.app/api/subscribe?token=${token}`);
      eventSource.onmessage = (event) => {
        console.log('Received SSE event:', event);
        const newEvent = JSON.parse(event.data);
        newEvent.priority = parseInt(newEvent.priority, 10); // Ensure priority is an integer
        newEvent.acknowledged = newEvent.acknowledged || false; // Ensure acknowledged field exists
        this.events.push(newEvent);
        this.events.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        this.checkForUnacknowledgedEvents();
      };
      eventSource.onerror = (e) => {
        console.error('SSE connection error:', e);
      };
    },
    toggleDetails(index) {
      this.expandedIndex = this.expandedIndex === index ? null : index;
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    setFilter(value) {
      this.filter = value;
      console.log('Filter set to:', value);
    },
    filterEvents() {
      // Function to handle filter changes if needed
      console.log('Filter changed to:', this.filter);
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'desc';
      }
      console.log(`Sorting by ${key} in ${this.sortOrder} order`);
    },
    enableAudioPlayback() {
      this.audio.play().then(() => {
        this.audio.pause();
        this.audio.currentTime = 0;
        document.removeEventListener('click', this.enableAudioPlayback);
      }).catch(error => {
        console.error('Failed to enable audio playback:', error);
      });
    },
    playAlertSound() {
      if (!this.isPlaying && this.audio) {
        this.audio.loop = true;
        this.audio.play().catch(error => {
          console.error('Failed to play alert sound:', error);
        });
        this.isPlaying = true;
      }
    },
    stopAlertSound() {
      if (this.isPlaying && this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.isPlaying = false;
        if (this.cooldownTimer) {
          clearTimeout(this.cooldownTimer);
        }
        this.cooldownTimer = setTimeout(() => {
          this.cooldownTimer = null;
          this.checkForUnacknowledgedEvents();
        }, 60000);
      }
    },
    checkForUnacknowledgedEvents() {
      this.unacknowledgedEvents = this.events.filter(event => event.priority > 0 && !event.acknowledged).map(event => event.id);
      if (this.unacknowledgedEvents.length > 0 && !this.cooldownTimer) {
        this.playAlertSound();
      } else if (this.unacknowledgedEvents.length === 0) {
        this.stopAlertSound();
      }
    },
    async acknowledgeEvent(eventId) {
      console.log('Acknowledging event:', eventId);  // Debugging log
      if (!eventId) {
        console.error('Event ID is undefined');
        return;
      }
      try {
        const response = await this.$http.post(`https://api.tractorbeam.app/api/events/${eventId}/ack`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.unacknowledgedEvents = this.unacknowledgedEvents.filter(id => id !== eventId);
        this.events = this.events.map(e => {
          if (e.id === eventId) {
            e.acknowledged = true;
          }
          return e;
        });
        this.checkForUnacknowledgedEvents();
      } catch (error) {
        console.error('Failed to acknowledge event:', error);
      }
    },
    toggleApiKeyVisibility() {
      this.apiKeyVisible = !this.apiKeyVisible;
    }
  }
};
</script>

<style scoped>
.container {
  padding: 20px;
}
h1 {
  margin-bottom: 10px;
}
button {
  margin-bottom: 20px;
}
.api-key-box {
  margin-bottom: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}
.api-key-container {
  display: flex;
  align-items: center;
}
.api-key {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  white-space: pre-wrap;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
}
.reveal-button {
  margin-left: 10px;
}
.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}
.table th, .table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.table th {
  background-color: #343a40;
  color: #fff;
}
.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}
.table-active {
  background-color: #dff0d8;
}
.event-details {
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  max-height: 500px;
  overflow-y: auto;
}
.btn-danger {
  margin-top: 10px;
}
.filter-dropdown {
  margin-bottom: 20px;
}
.filter-dropdown .form-select {
  width: auto;
  display: inline-block;
}
.high-priority {
  background-color: #f8d7da;
}
</style>
