import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiKey: localStorage.getItem('apiKey') || null,
    user: localStorage.getItem('user') || null
  },
  mutations: {
    setApiKey(state, apiKey) {
      state.apiKey = apiKey;
      localStorage.setItem('apiKey', apiKey);
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', user);
    },
    clearAuth(state) {
      state.apiKey = null;
      state.user = null;
      localStorage.removeItem('apiKey');
      localStorage.removeItem('user');
    }
  },
  actions: {
    login({ commit }, { apiKey, user }) {
      commit('setApiKey', apiKey);
      commit('setUser', user);
    },
    logout({ commit }) {
      commit('clearAuth');
    }
  },
  getters: {
    apiKey: state => state.apiKey,
    user: state => state.user
  }
});
